import { AccountApi } from "./accountApi";
import { ArticleApi } from "./articles/articleApi";
import { ArticleCategoryApi } from "./articles/articleCategoryApi";
import { CollectionApi } from "./collections/collectionApi";
import { MenuApi } from "./menus/menuApi";
import { ProductApi } from "./products/productApi";
import { ProductCategoryApi } from "./products/productCategoryApi";
import { ProductImageApi } from "./products/productImageApi";
import { RoleApi } from "./users/roleApi";
import { UserApi } from "./users/userApi";
import { UserGroupApi } from "./users/userGroupApi";

export const rootApi = {
    account: AccountApi,
    user: UserApi,
    userGroup: UserGroupApi,
    menus: MenuApi,
    role: RoleApi,
    articleCategory: ArticleCategoryApi,
    article: ArticleApi,
    productCategory: ProductCategoryApi,
    productImage: ProductImageApi,
    product: ProductApi,
    collection: CollectionApi
}
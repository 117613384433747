import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import { CollectionModel } from '../../context/models/collections/CollectionModel';
import { CollectionActionTypes } from '../../context/stores/collections/ICollectionActionsTypes';
// import ArticleCategoryDetail from './ArticleCategoryDetail';
type ICollectionPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allCollections: CollectionModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<CollectionModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: CollectionModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<CollectionModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Quản lý bộ sưu tập Collection`,
		status: state.collection.status,
		allCollections: state.collection.allCollections,
		selectedIds: state.collection.selectedIds,
		showDetail: state.collection.showDetail,
		showConfirm: state.collection.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<CollectionActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.collection.getAllRequest());
	},
	handleSaveData: (data: CollectionModel) => {
		dispatch(actions.collection.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.collection.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.collection.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<CollectionModel>) => {
		dispatch(actions.collection.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.collection.showConfirm(isShow));
	},
});

const CollectionPage = (props: ICollectionPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allCollections,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên bộ sưu tâp`} width={150} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='slug' minWidth={150} caption={`Slug`} fixed></DxTableColumn>,
			// <DxTableColumn key={createUUID()} dataField='parentId' width={150} caption={`Vị trí`}>
			// <DxLookup dataSource={allCollections} valueExpr={'id'} displayExpr={'name'} />
			// </DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='description' width={150} caption={`Mô tả`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='image' width={150} caption={`Ảnh`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='url' width={150} caption={`Link`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='isDelete' width={80} caption={`Trạng thái`} falseText='Áp dụng' trueText='Ẩn'></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allCollections]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allCollections}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{/* {showDetail && showDetail.isShow && (
				<>
					<ArticleCategoryDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)} */}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPage);

import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetailId } from '../../base/BaseShowDetail';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { dfProductActionConfirmRequest } from '../../models/products/ProductActionConfirmRequest';
import { ProductModel } from '../../models/products/ProductModel';
import { ProductActionTypes, eProductActionTypeIds } from './IProductActionsTypes';
import { IProductState } from './IProductState';

const initPaginationResponse: BasePaginationRespone<ProductModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IProductState = {
	status: eBaseActionStatus.idle,
	selectedIds: [],
	showConfirm: dfProductActionConfirmRequest,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetailId,
	detailResponse: undefined,
};
const productReducer = (state: IProductState = initialState, action: ProductActionTypes): IProductState => {
	switch (action.type) {
		case eProductActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_BY_ACTION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_BY_ACTION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_BY_ACTION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};

		case eProductActionTypeIds.GET_BY_OWNER_ACTION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_BY_OWNER_ACTION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_PUBLISHED_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_PUBLISHED_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_PUBLISHED_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};

		case eProductActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eProductActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eProductActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eProductActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_DETAIL_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.CHANGE_STATUS_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Cập nhật thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_DETAIL_SUCCESS:
			return {
				...state,
				detailResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa thành công');
			return {
				...state,
				selectedIds: [],
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.CHANGE_STATUS_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thay đổi thành công');
			return {
				...state,
				selectedIds: [],
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.GET_DETAIL_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				detailResponse: undefined,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.CHANGE_STATUS_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showDetail: dfBaseShowDetailId,
				showConfirm: dfProductActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eProductActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default productReducer;

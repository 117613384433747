import { accountActions } from "./auth/accountActions";
import { articleCategoryActions } from "./articles-category/articleCategoryActions";
import { articleActions } from "./articles/articleActions";
import { productCategoryActions } from "./products-categroy/productCategpryActions";
import { productActions } from "./products/productActions";
import { roleScopesActions } from "./roles/roleScopesActions";
import { rolesActions } from "./roles/rolesActions";
import { usersActions } from "./users/usersActions";
import { menusActions } from "./menus/menusActions";
import { userGroupActions } from "./users-group/userGroupActions";
import { CollectionActions } from "./collections/collectionActions";
import { productImageActions } from "./products-image/productImageActions";

export const actions = {
    users: usersActions,
    usersGroup: userGroupActions,
    menus: menusActions,
    account: accountActions,
    roles: rolesActions,
    roleScopes: roleScopesActions,
    articleCategory: articleCategoryActions,
    article: articleActions,
    product:productActions,
    productCategory: productCategoryActions,
    productImage: productImageActions,
    collection: CollectionActions
}
import { BasePagination, BasePaginationRespone } from '../../base/BasePaginationRespone'
import { BaseSelectRequest } from '../../base/BaseSelectRequest'
import { BaseShowDetail } from '../../base/BaseShowDetail'
import { BaseAction } from '../../base/IBaseActionRespone'
import { CollectionModel } from '../../models/collections/CollectionModel'
import { CollectionAction_CHANGE_SELECTED_IDS, CollectionAction_DELETE_Failure, CollectionAction_DELETE_Request, CollectionAction_DELETE_Success, CollectionAction_GETALL_Failure, CollectionAction_GETALL_Request, CollectionAction_GETALL_Success, CollectionAction_GETPAGINATION_Failure, CollectionAction_GETPAGINATION_Request, CollectionAction_GETPAGINATION_Success, CollectionAction_RELOAD, CollectionAction_SAVE_Failure, CollectionAction_SAVE_Request, CollectionAction_SAVE_Success, CollectionAction_SHOW_CONFIRM, CollectionAction_SHOW_DETAIL, eCollectionActionTypeIds } from './ICollectionActionsTypes'

export const CollectionActions = {
    //ALL
    getAllRequest: (): CollectionAction_GETALL_Request => BaseAction(eCollectionActionTypeIds.GET_ALL_REQUEST, undefined),
    getAllSuccess: (data: CollectionModel[]): CollectionAction_GETALL_Success => BaseAction(eCollectionActionTypeIds.GET_ALL_SUCCESS, data),
    getAllFailure: (error: Error | string): CollectionAction_GETALL_Failure => BaseAction(eCollectionActionTypeIds.GET_ALL_FAILURE, error),
    //LIST
    getPaginationRequest: (request: BaseSelectRequest): CollectionAction_GETPAGINATION_Request => BaseAction(eCollectionActionTypeIds.GET_PAGINATION_REQUEST, request),
    getPaginationSuccess: (data: BasePaginationRespone<CollectionModel>): CollectionAction_GETPAGINATION_Success => BaseAction(eCollectionActionTypeIds.GET_PAGINATION_SUCCESS, data),
    getPaginationFailure: (error: Error | string): CollectionAction_GETPAGINATION_Failure => BaseAction(eCollectionActionTypeIds.GET_PAGINATION_FAILURE, error),
    //SAVE
    saveRequest: (entity: CollectionModel): CollectionAction_SAVE_Request => BaseAction(eCollectionActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): CollectionAction_SAVE_Success => BaseAction(eCollectionActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): CollectionAction_SAVE_Failure => BaseAction(eCollectionActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: number[]): CollectionAction_DELETE_Request => BaseAction(eCollectionActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): CollectionAction_DELETE_Success => BaseAction(eCollectionActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): CollectionAction_DELETE_Failure => BaseAction(eCollectionActionTypeIds.DELETE_FAILURE, error),
    //RELOAD
    needReload: (): CollectionAction_RELOAD => BaseAction(eCollectionActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: number[]): CollectionAction_CHANGE_SELECTED_IDS => BaseAction(eCollectionActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetail<CollectionModel>): CollectionAction_SHOW_DETAIL => BaseAction(eCollectionActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (show: boolean): CollectionAction_SHOW_CONFIRM => BaseAction(eCollectionActionTypeIds.SHOW_CONFIRM, show)
}
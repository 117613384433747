import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { CollectionModel } from "../../models/collections/CollectionModel";

export enum eCollectionActionTypeIds {
    GET_ALL_REQUEST = "Collection_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "Collection_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "Collection_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "Collection_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "Collection_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "Collection_GET_PAGINATION_FAILURE",

    SAVE_REQUEST = "Collection_SAVE_REQUEST",
    SAVE_SUCCESS = "Collection_SAVE_SUCCESS",
    SAVE_FAILURE = "Collection_SAVE_FAILURE",

    DELETE_REQUEST = "Collection_DELETE_REQUEST",
    DELETE_SUCCESS = "Collection_DELETE_SUCCESS",
    DELETE_FAILURE = "Collection_DELETE_FAILURE",

    NEED_RELOAD = "Collection_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "Collection_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "Collection_SHOW_DETAIL",
    SHOW_CONFIRM = "Collection_SHOW_CONFIRM",
}

export interface CollectionAction_GETALL_Request extends IActionTypeBase<eCollectionActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface CollectionAction_GETALL_Success extends IActionTypeBase<eCollectionActionTypeIds.GET_ALL_SUCCESS, CollectionModel[]> { }
export interface CollectionAction_GETALL_Failure extends IActionTypeBase<eCollectionActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface CollectionAction_GETPAGINATION_Request extends IActionTypeBase<eCollectionActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface CollectionAction_GETPAGINATION_Success extends IActionTypeBase<eCollectionActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<CollectionModel>> { }
export interface CollectionAction_GETPAGINATION_Failure extends IActionTypeBase<eCollectionActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface CollectionAction_SAVE_Request extends IActionTypeBase<eCollectionActionTypeIds.SAVE_REQUEST, CollectionModel> { }
export interface CollectionAction_SAVE_Success extends IActionTypeBase<eCollectionActionTypeIds.SAVE_SUCCESS, string> { }
export interface CollectionAction_SAVE_Failure extends IActionTypeBase<eCollectionActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface CollectionAction_DELETE_Request extends IActionTypeBase<eCollectionActionTypeIds.DELETE_REQUEST, number[]> { }
export interface CollectionAction_DELETE_Success extends IActionTypeBase<eCollectionActionTypeIds.DELETE_SUCCESS, string> { }
export interface CollectionAction_DELETE_Failure extends IActionTypeBase<eCollectionActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface CollectionAction_RELOAD extends IActionTypeBase<eCollectionActionTypeIds.NEED_RELOAD, undefined> { }

export interface CollectionAction_SHOW_DETAIL extends IActionTypeBase<eCollectionActionTypeIds.SHOW_DETAIL, BaseShowDetail<CollectionModel>> { }
export interface CollectionAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eCollectionActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface CollectionAction_SHOW_CONFIRM extends IActionTypeBase<eCollectionActionTypeIds.SHOW_CONFIRM, boolean> { }

export type CollectionActionTypes = CollectionAction_GETALL_Request | CollectionAction_GETALL_Success | CollectionAction_GETALL_Failure |
    CollectionAction_GETPAGINATION_Request | CollectionAction_GETPAGINATION_Success | CollectionAction_GETPAGINATION_Failure |
    CollectionAction_SAVE_Request | CollectionAction_SAVE_Success | CollectionAction_SAVE_Failure |
    CollectionAction_DELETE_Request | CollectionAction_DELETE_Success | CollectionAction_DELETE_Failure | CollectionAction_RELOAD |
    CollectionAction_SHOW_DETAIL | CollectionAction_CHANGE_SELECTED_IDS | CollectionAction_SHOW_CONFIRM 